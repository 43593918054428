
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  name: 'TmUploadFile',
  components: {
    TmButton,
  },
  setup() {
    const fileInput = ref()
    const uploadDragOver = () => {
      fileInput.value.classList.add('tm-upload-file--active')
    }

    const uploadMouseLeave = () => {
      fileInput.value.classList.remove('tm-upload-file--active')
    }

    const dragAndDrop = (e: any) => {
      e.preventDefault()
      uploadMouseLeave()
    }

    return {
      fileInput,
      uploadDragOver,
      uploadMouseLeave,
      dragAndDrop,
    }
  },
})
